import dynamic from 'next/dynamic'
import type { ComponentType } from 'react'

const FastestTrains = dynamic(() => import('@components/mainPageBlocks/FastestTrains'))
const TravelInformation = dynamic(() => import('@components/mainPageBlocks/TravelInformation'))
const PopularTrainRoutes = dynamic(() => import('@components/mainPageBlocks/PopularTrainRoutes'))

export enum CustomContentId {
  RoutePageFastestTrains = 'route_page_fastest_trains',
  RoutePageOverall = 'route_page_overall',
  RoutePageRelatedRouteRages = 'route_page_related_route_pages',
}

type CustomContentBlocksType = {
  [key in CustomContentId]: ComponentType<{ contentId: CustomContentId }>
}

export const customContentBlockList: CustomContentBlocksType = {
  [CustomContentId.RoutePageFastestTrains]: FastestTrains,
  [CustomContentId.RoutePageOverall]: TravelInformation,
  [CustomContentId.RoutePageRelatedRouteRages]: PopularTrainRoutes,
}
