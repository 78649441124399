import type { DividerProps } from 'antd/es/divider'
import type { FC } from 'react'

import { StyledDivider } from './styles'

const Divider: FC<DividerProps> = props => {
  return <StyledDivider dashed {...props} />
}

export default Divider
