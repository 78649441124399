import type { CSSProperties, FC, HTMLAttributes, ReactNode, RefObject } from 'react'

import { CaptionStyled } from './styles'

export type captionSizeType = 'l' | 'm' | 's' | 'xs'

type Props = {
  children?: ReactNode
  refProp?: RefObject<HTMLSpanElement>
  size?: captionSizeType
  style?: CSSProperties
} & HTMLAttributes<HTMLElement>

export const Caption: FC<Props> = ({ children, refProp, size = 'm', ...props }) => {
  return (
    <CaptionStyled ref={refProp} size={size} {...props}>
      {children}
    </CaptionStyled>
  )
}
