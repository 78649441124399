import { type FC, memo } from 'react'

import SiteLogo from '@components/SiteLogo'
import type { GoHomeHocProps } from '@hocs/links/GoHomeHoc'
import GoHomeHoc from '@hocs/links/GoHomeHoc'

const LogoWithHomeLink: FC<Omit<GoHomeHocProps, 'children'>> = props => {
  return (
    <GoHomeHoc {...props}>
      <SiteLogo />
    </GoHomeHoc>
  )
}

export default memo(LogoWithHomeLink)
