import styled from 'styled-components'

import Button from '@base/buttons/Button'
import type { ThemeColorsType } from '@themes/themes'
import type { SizeType, VariantType } from '@typography/Text'
import { stylesText } from '@typography/Text'

export const TextButtonStyled = styled(Button)<{
  $color: ThemeColorsType
  $colorHover: ThemeColorsType
  $sizeText?: SizeType
  $underline: boolean
  $variant?: VariantType
}>`
  &.ant-btn {
    span {
      ${p => stylesText[p.$sizeText || 'm'][p.$variant || 'regular']};
      text-transform: initial;
      color: ${p => p.theme.colors[p.$color]};
    }

    &:hover {
      span {
        color: ${p => (p.$underline ? 'inherit' : p.theme.colors[p.$colorHover])};
        text-decoration: ${p => (p.$underline ? 'underline' : 'none')};
      }
    }
  }
`
