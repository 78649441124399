import Switch from 'antd/es/switch'
import styled from 'styled-components'

import { captionSmallStyles } from '@styles/typography/caption'

export const SwitcherWrapper = styled(Switch)`
  width: 100%;
  height: 48px;
  border-radius: ${p => p.theme.borderRadius.default};

  &.ant-switch-checked .ant-switch-handle {
    inset-inline-start: 50%;
  }

  .ant-switch-handle {
    height: 100%;
    border-radius: inherit;
    width: 50%;
    top: 0;
    inset-inline-start: 0;
    box-shadow: ${p => p.theme.shadows.button};

    &:before {
      background: ${p => p.theme.colors.switcherBackground};
      border-radius: inherit;
      box-shadow: ${p => p.theme.shadows.button};
    }
  }

  &.ant-switch:not(.ant-switch-disabled),
  &.ant-switch:hover:not(.ant-switch-disabled) {
    background: ${p => p.theme.colors.backgroundAdditionalForm};
  }

  &.ant-switch .ant-switch-inner .ant-switch-inner-checked,
  &.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-unchecked {
    color: ${p => p.theme.colors.buttonTextForm};
  }

  &.ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
  &.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked {
    color: ${p => p.theme.colors.fontSecondary};
  }

  &.ant-switch.ant-switch-checked .ant-switch-inner,
  &.ant-switch .ant-switch-inner {
    width: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-inline-start: 0;
    padding-inline-end: 0;

    .ant-switch-inner-unchecked,
    .ant-switch-inner-checked {
      ${captionSmallStyles};
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      width: 50%;
      padding: 0 20px;
      height: fit-content;
      display: -webkit-box;
      max-width: 50%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`
