import { useGetHeaderConfigQuery, useGetMenuItemsQuery } from '@redux/features/header/api'
import { skipToken } from '@reduxjs/toolkit/query/react'

import defaultParameters from '@constants/defaultParameters/defaultParameters'
import useLangCode from '@hooks/useLangCode/useLangCode'

const useGetMenuItems = () => {
  const langCode = useLangCode()

  const { data: headerConfig } = useGetHeaderConfigQuery()
  const { data: menuData } = useGetMenuItemsQuery(
    headerConfig?.menu_reference[langCode || defaultParameters.langcode] || skipToken
  )

  return menuData
}

export default useGetMenuItems
