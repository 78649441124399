import styled from 'styled-components'

export const MainPageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 32px auto 0 auto;
  display: flex;
  background: ${p => p.theme.colors.backgroundPage};
  flex-direction: column;
  overflow: hidden;

  ${p => p.theme.mediaQueries.mobile} {
    margin: 0 auto;
  }
`

export const MainPageContainerContentWrapper = styled.main`
  width: 100%;
  max-width: ${p => p.theme.sizes.contentMaxWidth + p.theme.padding.tabletBlock * 2}px;
  padding-inline: ${p => p.theme.padding.tabletBlock}px;
  position: relative;
  margin: 40px auto 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 90px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    margin: 128px 0 0 0;
    gap: 60px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    gap: 30px;
    padding: 0;
    width: 100%;
    margin-top: 0;
  }
`
