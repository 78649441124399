import styled from 'styled-components'

import { CaptionStyled } from '@typography/Caption/styles'

export const WrapperInfoItem = styled.div<{ area?: string }>`
  height: fit-content;
  display: grid;
  grid-gap: 4px;

  & > * {
    text-align: end;
    justify-self: end;
    width: fit-content;
  }

  ${CaptionStyled} {
    color: ${p => p.theme.colors.fontSecondary};
    white-space: nowrap;

    ${p => p.theme.mediaQueries.mobileTablet} {
      white-space: unset;
    }
  }
`
