import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { StyledText } from '@typography/Text'

export const InformationBlockHeaderStyled = styled.header`
  position: relative;
  padding: 20px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.colors.backgroundMain};

  ${StyledText} {
    color: ${p => p.theme.colors.fontSecondary};
  }
`

export const ButtonHeaderStyled = styled(Button)`
  &.ant-btn.ant-btn-link {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: ${p => p.theme.colors.primary};

    svg path {
      fill: ${p => p.theme.colors.primary};
    }

    &:hover {
      svg {
        fill: ${p => p.theme.colors.primary};
      }
    }

    svg:first-child {
      margin-inline: 0;
    }
  }
`
