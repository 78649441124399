import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import { searchFormSizes, topPartHeightDesktop, topPartHeightMobile } from '../searchForm/constants/sizes'

const Background: FC = () => {
  const withLayover = useAppSelector(selectWithLayover)

  return (
    <Wrapper>
      <Gradient $isLayoverValue={withLayover} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  border-radius: inherit;
  filter: drop-shadow(${p => p.theme.shadows.modal});
  z-index: 3;
  background: ${p => p.theme.colors.backgroundMain};
  transform: translateZ(0);
`

const Gradient = styled.div<{ $isLayoverValue: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 1px solid ${p => p.theme.colors.primary25};
  z-index: 3;
  background: linear-gradient(
    to bottom,
    transparent 0,
    ${p =>
      p.$isLayoverValue
        ? `rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) ${
            topPartHeightDesktop + searchFormSizes.layoversHeightDesktop - searchFormSizes.fieldHeightDesktop
          }px,
    transparent ${topPartHeightDesktop + searchFormSizes.layoversHeightDesktop - searchFormSizes.fieldHeightDesktop}px`
        : `transparent ${searchFormSizes.fieldHeightDesktop}px,
    rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) ${searchFormSizes.fieldHeightDesktop}px`},
    transparent
      ${p =>
        p.$isLayoverValue ? topPartHeightDesktop + searchFormSizes.layoversHeightDesktop : topPartHeightDesktop}px,
    ${p =>
      p.$isLayoverValue
        ? `rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) 
        ${topPartHeightDesktop + searchFormSizes.layoversHeightMobile - searchFormSizes.fieldHeightDesktop}px`
        : `rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) ${topPartHeightDesktop}px`},
    transparent 100%
  );

  ${p => p.theme.mediaQueries.mobile} {
    background: linear-gradient(
      to bottom,
      transparent 0,
      ${p =>
        p.$isLayoverValue
          ? `rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) ${
              topPartHeightMobile + searchFormSizes.layoversHeightMobile - searchFormSizes.fieldHeightMobile
            }px,
    transparent ${topPartHeightMobile + searchFormSizes.layoversHeightMobile - searchFormSizes.fieldHeightMobile}px`
          : `transparent ${searchFormSizes.fieldHeightMobile}px,
    rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) ${searchFormSizes.fieldHeightMobile}px`},
      transparent ${topPartHeightMobile}px,
      ${p =>
        p.$isLayoverValue
          ? `rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) 
        ${topPartHeightMobile + searchFormSizes.layoversHeightMobile - searchFormSizes.fieldHeightMobile}px`
          : `rgba(0, 0, 0, ${p.theme.gradient.gradientFillOpacity}) ${topPartHeightMobile}px`},
      transparent 100%
    );
    border: none;
  }
`

export default memo(Background)
