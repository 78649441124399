import styled, { type RuleSet } from 'styled-components'

import {
  captionLargeStyles,
  captionMediumStyles,
  captionSmallStyles,
  captionUltraSmallStyles,
} from '@styles/typography/caption'

import type { captionSizeType } from './Caption'

export const CaptionStyled = styled.span<{ size: captionSizeType }>`
  ${p => styles[p.size]};
  position: relative;
`

const styles: Record<captionSizeType, RuleSet<object>> = {
  l: captionLargeStyles,
  m: captionMediumStyles,
  s: captionSmallStyles,
  xs: captionUltraSmallStyles,
}
