import styled from 'styled-components'

import { WrapperInfoItem } from '@components/mainPage/mainBlock/searchTrains/info/InfoItem/styles'

export const InfoWrapper = styled.div`
  grid-area: info;
  align-self: end;
  height: fit-content;
  display: flex;
  grid-template-columns: minmax(80px, 300px);
  flex-direction: row;
  gap: 30px;
  justify-content: flex-end;

  ${p => p.theme.mediaQueries.mobileTablet} {
    ${WrapperInfoItem}:nth-child(3) {
      display: none;
    }
  }
`
