import type { FC } from 'react'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { NodeCustomContentType } from '@Types/blocks/nodeCustomContent'
import { customContentBlockList } from '@constants/blockList/customContentBlockList'

type Props = {
  data: ExtendBlocksType
}
const NodeCustomContentBock: FC<Props> = ({ data }) => {
  const { custom_content_id } = data as NodeCustomContentType
  const Element = customContentBlockList[custom_content_id]

  return Element ? <Element contentId={custom_content_id} /> : null
}

export default NodeCustomContentBock
