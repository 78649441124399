import styled from 'styled-components'

import { InfoWrapper } from '@components/mainPage/mainBlock/searchTrains/info/styles'
import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'

export const WrapperSearchTrains = styled.section`
  width: 100%;
  max-width: ${p => p.theme.sizes.contentMaxWidth}px;
  display: grid;
  grid-template-columns: ${searchFormSizes.formWidthDesktop}px 1fr;
  grid-gap: 48px;
  grid-template-areas: 'form title' 'form info';
  margin: 0 auto;
  position: relative;

  ${p => p.theme.mediaQueries.mobileTablet} {
    grid-row-gap: 10px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-areas: 'title' 'form';
    grid-template-columns: 100%;
    padding: 0;

    ${InfoWrapper} {
      display: none;
    }
  }
`
