import type { FC, ReactNode } from 'react'
import { memo } from 'react'

import { UilArrowLeft } from '@iconscout/react-unicons'

import { ButtonHeaderStyled, InformationBlockHeaderStyled } from './styles'

type Props = {
  autoTestAttr?: string
  children: ReactNode
  close: () => void
  disabled?: boolean
}

const InformationBlockHeader: FC<Props> = ({ autoTestAttr, children, close, disabled }) => {
  return (
    <InformationBlockHeaderStyled>
      <ButtonHeaderStyled data-attr-autotest={autoTestAttr} disabled={disabled} onClick={close} type="link">
        <UilArrowLeft />
      </ButtonHeaderStyled>
      {children}
    </InformationBlockHeaderStyled>
  )
}

export default memo(InformationBlockHeader)
