import type { ElementType, FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

import {
  title1Styles,
  title2Styles,
  title3Styles,
  title4Styles,
  title5Styles,
  title6Styles,
  title7Styles,
  title8Styles,
} from '@styles/typography/title'

type LevelType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

type Props = {
  children: ReactNode
  level: LevelType
  setInnerHtml?: boolean
} & HTMLAttributes<HTMLElement>

const Title: FC<Props> = ({ children, level, setInnerHtml, ...props }) => {
  const Component = components[level]
  const isInnerHtml = setInnerHtml && typeof children === 'string'

  return (
    <Component dangerouslySetInnerHTML={isInnerHtml ? { __html: children } : undefined} {...props}>
      {isInnerHtml ? undefined : children}
    </Component>
  )
}

export const Title1 = styled.h1`
  ${title1Styles};
`
export const Title2 = styled.span`
  ${title2Styles};
`

export const Title3 = styled.span`
  ${title3Styles};
`

export const Title4 = styled.span`
  ${title4Styles};
`

export const Title5 = styled.span`
  ${title5Styles};
`

export const Title6 = styled.span`
  ${title6Styles};
`

export const Title7 = styled.span`
  ${title7Styles};
`

export const Title8 = styled.span`
  ${title8Styles};
`

const components: Record<LevelType, ElementType> = {
  1: Title1,
  2: Title2,
  3: Title3,
  4: Title4,
  5: Title5,
  6: Title6,
  7: Title7,
  8: Title8,
}

export default Title
