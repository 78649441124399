import type { ButtonProps } from 'antd/es'
import type { FC } from 'react'

import type { ThemeColorsType } from '@themes/themes'
import type { SizeType, VariantType } from '@typography/Text'

import { TextButtonStyled } from './styles'

export type TextButtonProps = ButtonProps & {
  color?: ThemeColorsType
  colorHover?: ThemeColorsType
  iconColor?: ThemeColorsType
  sizeText?: SizeType
  underline?: boolean
  variantText?: VariantType
}

const TextButton: FC<TextButtonProps> = ({
  children,
  color = 'fontEmptyLabel',
  colorHover = 'primary',
  sizeText,
  underline = false,
  variantText,
  ...props
}) => {
  return (
    <TextButtonStyled
      $sizeText={sizeText}
      $variant={variantText}
      type="link"
      {...props}
      $color={color}
      $colorHover={colorHover}
      $underline={underline}
    >
      {children}
    </TextButtonStyled>
  )
}

export default TextButton
