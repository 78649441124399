import {
  formHeightDesktop,
  formHeightMobile,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'

export const getSearchFormHeight = (isLayover: boolean, isMobile?: boolean): number => {
  const baseHeight = isMobile ? formHeightMobile : formHeightDesktop
  const layoverHeight = isMobile ? searchFormSizes.layoversHeightMobile : searchFormSizes.layoversHeightDesktop
  return isLayover ? baseHeight + layoverHeight : baseHeight
}
