import type { FC } from 'react'
import { memo } from 'react'

import Caption from '@typography/Caption'
import Title from '@typography/Title'

import { WrapperInfoItem } from './styles'

type Props = {
  description: string
  title: string
}

const InfoItem: FC<Props> = ({ description, title }) => {
  return (
    <WrapperInfoItem>
      <Caption size="s">{title}</Caption>
      <Title level={3}>{description}</Title>
    </WrapperInfoItem>
  )
}

export default memo(InfoItem)
