import styled from 'styled-components'

import { title2Styles } from '@styles/typography/title'
import TitleComponent from '@typography/Title'

export const TitleStyled = styled(TitleComponent)`
  grid-area: title;
  width: 100%;
  padding-inline-start: calc(100% - 500px);
  justify-self: end;
  text-indent: 120px;
  text-align: end;

  ins {
    text-underline-offset: 10px;
    text-decoration-color: ${p => p.theme.colors.primary};
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    text-indent: 0;
  }

  ${p => p.theme.mediaQueries.mobile} {
    ${title2Styles};
    text-align: initial;
    padding: 20px 20px 0 20px;
  }
`
